import React from 'react';
import {connect} from 'react-redux';
import {formatMoney} from "../../pipes/priceFormatter";
import {addProductToCart} from "../../actions";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { makeStyles } from '@material-ui/core/styles';
import Table from 'react-bootstrap/Table';


import ReactGA from 'react-ga';
import {trackGA} from "../../utilities/gaUtility";

const ProductDetail = (props) => {
    const useStyles = makeStyles({
        table: {
          minWidth: 650,
        },
      });

    const {
        title,
        description,
        name,
        vendors,
        summaryOffer,
        identifier
    } = props.product;

    
    trackGA(ReactGA,'product/' + identifier);

    const removeItem = () => {
        props.removeItem(identifier);
    };

    const onCart = () => {
        props.dispatch(addProductToCart(props.product));
    };

    return (
        <aside className="col-sm-7">
            <article className="card-body p-5">
                <div><h3 className="title mb-3">{title}</h3>

                <p className="price-detail-wrap">
	<span className="price h3 text-warning">
		<span className="currency">{name}</span><p/>
        {summaryOffer && 
        <span className="num">{summaryOffer.priceRange.formattedValue}</span>
        }
	</span>
                </p>
                <dl className="item-property">
                    <dd><p className="param param-feature">{ ReactHtmlParser(description) }</p></dd>
                </dl>
                {/*<dl className="param param-feature">
                    <dt>Brand</dt>
                    <dd className="text-capitalize">{brand}</dd>
                </dl>
                <dl className="param param-feature">
                    <dt>Size</dt>
                    <dd>{size}</dd>
                </dl>
                <dl className="param param-feature">
                    <dt>Camera</dt>
                    <dd>{camera}</dd>
                </dl>
                <dl className="param param-feature">
                    <dt>CPU</dt>
                    <dd>{cpu}</dd>
                </dl>
                <dl className="param param-feature">
                    <dt>Memory</dt>
                    <dd>{memory}</dd>
                </dl>
                <dl className="param param-feature">
                    <dt>Display</dt>
                    <dd>{display}</dd>
                </dl>
                <dl className="param param-feature">
                    <dt>Battery</dt>
                    <dd>{battery}</dd>
    </dl>
    <hr/>*/}
    </div>
    
        {vendors &&
        <>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Prodavnica</th>
                        <th align="center">Cena</th>
                        <th align="center">Isporuka</th>
                        <th align="center">Stanje</th>
                        <th align="center">Link</th>
                    </tr>
                </thead>
                <tbody>
                    {vendors.map((row) => (
                        row.name &&
                        <tr>
                        <td>
                            {row.name}
                        </td>
                        <td align="center">
                            {row.price.formattedValue}
                        </td>
                        <td align="center">
                            {row.deliveryDetails &&
                                row.deliveryDetails.data}
                    </td>
                        <td align="center">
                            {row.stockStatus &&
                                row.stockStatus.stockLevelStatus}
                            </td>
                        <td>   
                            <LinkRef url={row.productUrl} userid={props.userid} productid={identifier} vendorid={row.identifier}/>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
        }
                
                <hr/>
                {props.type!=="Cart" &&
                <button
                    onClick={onCart}
                    className="btn btn-lg btn-outline-primary text-uppercase"><i
                    className="fa fa-shopping-cart"/> Dodaj u uporedi
                </button>
                }
                {props.type==="Cart" &&
                    <button
                        onClick={removeItem}
                        className="btn btn-lg btn-outline-primary text-uppercase"><i
                        className="fa fa-shopping-cart"/> Obrisi
                    </button>
                }
            </article>
        </aside>
    );
};

const LinkRef=(props)=> {
    const url='/navigate?id='+props.userid+'&url='+props.url+'&productid='+props.productid+'&vendor='+props.vendorid;
    return(
        <a style={{display: "table-cell"}} href ={url} target = "_blank" 
        rel = "noopener noreferrer">Link</a>
    )
}

export default connect()(ProductDetail);
