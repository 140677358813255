import React from 'react'
import { connect } from 'react-redux'
import { loadProducts } from '../actions'

const ButtonSearch = (props ) => ( 
  <button
    onClick={() => { props.getProducts(props.search) }}
    className="btn btn-primary btn-lg btn-block" >
    Search
  </button>
); 

const mapStateToProps = (state) => 
{
    //if(state.search.searchFilter.length>0)
    //{
      return {
      search: state.search
    //}
  }
  }
  

const mapDispatchToProps = {
  getProducts: loadProducts
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonSearch)

