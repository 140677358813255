import React, {Component} from 'react';
import {connect} from 'react-redux';
import Product from "../../components/Product/Product";

import {brandFilter} from "../../pipes/brandFilter";
import {orderByFilter} from "../../pipes/orderByFilter";

import {orderByFilterLoad} from "../../pipes/orderByFilterLoad";
import LayoutMode from "../../components/LayoutMode/LayoutMode";
import {paginationPipe} from "../../pipes/paginationFilter";
import Pagination from "../../components/Pagination/Pagination";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import { getProducts, loadProducts,addedCategoryToFilter } from '../../actions'

class ProductList extends Component {
    constructor(props) {
        super(props);
      }
    state = {
        colValue : 'col-lg-3',
        perPage: 12,
        currentPage: 1,
        pagesToShow: 3,
        gridValue: 4
    };

    componentDidUpdate(){
        const {dispatch,shouldLoadProducts, search} = this.props;
        if(shouldLoadProducts)
        {
            dispatch(addedCategoryToFilter());
             dispatch(getProducts(search));
             dispatch(loadProducts(search));
             this.goPage(1);
        }
    }


    changeLayout = (n) => {
        this.setState({gridValue: n});

        let realGridValue;

        if(n === 4) {
            realGridValue = 3
        } else {
            realGridValue = 4;
        }

      this.setState({
          colValue: `col-lg-${realGridValue}`
      });
    };


    onPrev = () => {
        const updatedState = {...this.state};
        updatedState.currentPage = this.state.currentPage - 1;
        this.setState(updatedState);
    };


    onNext = () => {
        this.setState({
            ...this.state,
            currentPage: this.state.currentPage + 1
        });
    };

    goPage = (n) => {
        this.setState({
            ...this.state,
            currentPage: n
        });
    };


    useStyles = () =>{ 
        return makeStyles((theme) => ({
        root: {
          display: 'flex',
          '& > * + *': {
            marginLeft: theme.spacing(2)
          },
          flexDirection:'column',
          justifyContent:'center',
          alignItems: 'center',
          height: '100%'
        },
      }
        ))};
    

    

    render() {

        let isActive = this.state.colValue[this.state.colValue.length -1];
        
        //this.getPosts();
    
        return (
            <div className="col-lg-8">
                {!this.props.loading && this.props.products.length>0 &&
                <>
                <div className="row mb-3">
                    <div className="col-12 d-none d-lg-block d-xl-block">
                        <div className="card">
                            <div className="card-header d-flex justify-content-end">
                                <span className="mr-5">Rezultat:{this.props.products.length}</span>
                                {this.props.search.searchFilter.length>0 && 
                                    <>
                                        {this.props.search.searchFilter.map(item => {
                                            if(item.values)
                                            {
                                            return (<span>{item.values.map(x=>`(`+x+`)`)}</span>) 
                                            }
                                        }
                                        )}
                                    </>
                                }
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-end">
                                <span className="mr-3">Prikaz: </span>
                                <LayoutMode len={3} isActive={this.state.gridValue === 3} click={this.changeLayout} />
                                <LayoutMode len={4} isActive={this.state.gridValue === 4}  click={this.changeLayout} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {paginationPipe(this.props.products, this.state).map(product =>{
                        let classes = `${this.state.colValue} col-md-6 mb-4`;
                        return (<div className={classes}>
                            <Product key={product.identifier} product={product} selectedCategory={this.props.selectedCategory}/>
                        </div>)
                    })}
                </div>
                <div className="d-flex justify-content-end">
                    <Pagination
                        totalItemsCount={this.props.products.length}
                        currentPage={this.state.currentPage}
                        perPage={this.state.perPage}
                        pagesToShow={this.state.pagesToShow}
                        onGoPage={this.goPage}
                        onPrevPage={this.onPrev}
                        onNextPage={this.onNext}
                    />
                </div>
                </>}
                {this.props.loading &&
                <div className={this.useStyles()}><CircularProgress/></div>
                }
            </div>
        );
    }
}  

const mapDispatchToProps = (dispatch) => {
    return {
        getProducts: loadProducts
    }
  }

const mapStateToProps = (state, props) => {
    const brands = state.brandFilter;
    const orderBy = state.orderBy;
    let searchCategory=null;

    if(state.search)
        searchCategory=state.search.category;

    const loadCategory = state.search.categoryLoad;

    let products=state.shop.products;
    if(state.products.products)
        {
            products=state.products.products;
            state.shop.products=products;
        }

    const filterByBrandArr = brandFilter(products, brands, state.attributes.brandAttributeId);
    const filterByOrderArr = orderByFilter(filterByBrandArr, orderBy);//orderByFilter
    //const fetchedProducts= getPosts();

    return {
        products: filterByOrderArr, 
        selectedCategory:searchCategory, 
        loading:state.products.loading,
        search: state.search,
        shouldLoadProducts: loadCategory }//filterByOrderArr
};




export default connect(mapStateToProps)(ProductList);
