import {ADD_PRICE_TO_FILTER, REMOVE_PRICE_FROM_FILTER} from "../actions";

export const  priceFilterReducer = (state = '', action) => {
    switch (action.type) {
        case REMOVE_PRICE_FROM_FILTER:
            console.log('remove price', action);
            const reg = new RegExp(action.brand, 'gi');
            return state.replace(reg, '');
        default:
            return state;
    }
};

