import {INVOKED_SEARCH, 
    GET_PRODUCTS_RECEIVED, 
    ADD_PRICE_TO_FILTER, 
    ADD_SEARCHTEXT_TO_FILTER, 
    ADD_CATEGORY_TO_FILTER, 
    ADDED_CATEGORY_TO_FILTER, 
    ADD_SIMPLE_SEARCH_ATTRIBUTE, 
    REMOVE_SIMPLE_SEARCH_ATTRIBUTE, 
    ADD_SEARCH_ATTRIBUTE, 
    REMOVE_SEARCH_ATTRIBUTE, 
    ADD_BRAND_TO_FILTER,
    LOGIN_USER,
    REMOVE_BRAND_FROM_FILTER} from "../actions";

const initialState={
    searchFilter:[]
}
export const  searchFilterReducer = (state = initialState, action) => {
    let updatedFilter;
    let updatedItemIndex;

    switch (action.type) {
        case LOGIN_USER:
            {
                return {...state, userid: action.userid};
            }
        case GET_PRODUCTS_RECEIVED:
            {
                if(!action.attributes)
                    return state;
                    
                const brandAttribute=action.attributes.findIndex(item => item.type === "brand");

                return { ...state, attributes:action.attributes, loading: false, brandAttributeId: action.attributes[brandAttribute], 
                    searchFilter:[...state.searchFilter] };
            }
        case INVOKED_SEARCH:
            return {...state, categoryLoad:false,filterchanged:false};
        case ADD_PRICE_TO_FILTER:
            return {...state, priceRange: action.priceRangeFilter};
        case ADD_SEARCHTEXT_TO_FILTER:
            {
                return {...state, text: action.text}; 
            }
        case ADD_CATEGORY_TO_FILTER:
            {
                state.searchFilter=[];
                return {...state, category: action.categoryId, categoryLoad:true};
            }
        case ADDED_CATEGORY_TO_FILTER:
            {
                return {...state, categoryLoad:false};
            }
        case ADD_SIMPLE_SEARCH_ATTRIBUTE:
            {
                updatedFilter = [...state.searchFilter];
            
                addsimplevalueintofilter(updatedFilter,action.value, action.id);

                return {...state, searchFilter: updatedFilter,filterchanged:true};
            }
        case REMOVE_SIMPLE_SEARCH_ATTRIBUTE:
        {
            updatedFilter = [...state.searchFilter];
            
            removesimplevaluefromfilter(updatedFilter,action.value, action.id);

            return {...state, searchFilter: updatedFilter,filterchanged:true};
        }
        case ADD_SEARCH_ATTRIBUTE:
            {
                updatedFilter = [...state.searchFilter];
            
                addintofilter(updatedFilter,action.value, action.id);

                return {...state, searchFilter: updatedFilter,filterchanged:true};
            }
        case REMOVE_SEARCH_ATTRIBUTE:
        {
            updatedFilter = [...state.searchFilter];
            
            removefromfilter(updatedFilter,action.value, action.id);

            return {...state, searchFilter: updatedFilter,filterchanged:true};
        }
        case ADD_BRAND_TO_FILTER:{
            updatedFilter = [...state.searchFilter];
            
            addintofilter(updatedFilter,action.brand, state.brandAttributeId.refId);

            return {...state, searchFilter: updatedFilter};
        }
        case REMOVE_BRAND_FROM_FILTER:
            {
                updatedFilter = [...state.searchFilter];
                
                removefromfilter(updatedFilter,action.brand, state.brandAttributeId.refId);

                /*updatedItemIndex = updatedFilter.findIndex(item => item.ref === state.brandAttributeId.refId);

                if(updatedItemIndex < 0) {
                    return state;
                } else {
                    const updatedItem = {
                        ...updatedFilter[updatedItemIndex]
                    };
                    //try to remove value if exist
                    if(updatedItem.values.includes(action.brand))
                    {
                        if(updatedItem.values.length>1)
                        {
                            updatedItem.values=splice(updatedItem.values,action.brand);
                            updatedFilter[updatedItemIndex] = updatedItem;
                        }
                        else
                        {
                            //to delete
                            updatedFilter.splice(updatedItemIndex,1);
                        }
                        return {...state, searchFilter: updatedFilter};
                    }
                    
                }*/

                return {...state, searchFilter: updatedFilter};
            }
        default:
            return state;
    }
};

function removefromfilter(arr, val, valid)
{
    let updatedItemIndex;

    updatedItemIndex = arr.findIndex(item => item.ref === valid);

    if(updatedItemIndex < 0) {
        return;
    } else {
        const updatedItem = {
            ...arr[updatedItemIndex]
        };
        //try to remove value if exist
        if(updatedItem.values.includes(val))
        {
            if(updatedItem.values.length>1)
            {
                splice(updatedItem.values,val);
                if(updatedItem.values.length<1)
                {
                    arr.splice(updatedItemIndex,1);
                }
                else
                {
                    arr[updatedItemIndex] = updatedItem;
                }
            }
            else
            {
                //to delete
                arr.splice(updatedItemIndex,1);
            }
        }
        
    }
}

function removesimplevaluefromfilter(arr, val, valid)
{
    let updatedItemIndex;

    updatedItemIndex = arr.findIndex(item => item.ref === valid);

    if(updatedItemIndex < 0) {
        return;
    } else 
        arr.splice(updatedItemIndex,1);
    
}

function addsimplevalueintofilter(arr, val, valid)
{
    let updatedItemIndex;
    if (typeof valid === 'undefined')
        return;

    updatedItemIndex = arr.findIndex(item => item.ref === valid);

    if(updatedItemIndex < 0) {
        arr.push({ref:valid, valueContent: val});
    } 
}


function addintofilter(arr, val, valid)
{
    let updatedItemIndex;
    if (typeof valid === 'undefined')
        return;

    updatedItemIndex = arr.findIndex(item => item.ref === valid);

    if(updatedItemIndex < 0) {
        arr.push({ref:valid, values: [val]});
    } else {
        const updatedItem = {
            ...arr[updatedItemIndex]
        };

        updatedItem.values=[...updatedItem.values,val];

        arr[updatedItemIndex] = updatedItem;
    }
}

function splice(arr, val) {
    for (var i = arr.length; i--;) {
      if (arr[i] === val) {
        arr.splice(i, 1);
      }
    }
  }

