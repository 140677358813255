import React from 'react';
import {connect} from 'react-redux';
import ProductDetailComponent from '../../components/ProductDetail/ProductDetail';
import ProductSlider from "../../components/ProductSlider/ProductSlider";
import {loadProduct,loadCategories,loadSystemParameters} from "../../actions";

import ReactGA from 'react-ga';
import {trackGA} from "../../utilities/gaUtility";

import { Helmet } from "react-helmet";
class ProductDetail extends React.Component{

    componentDidMount(){
        window.scrollTo(0, 0);
        const {dispatch, product, id} = this.props;

        console.log(this.props);

        trackGA(ReactGA,window.location.pathname + window.location.search);
        
        if(!product)
            {
                dispatch(loadCategories());
                dispatch(loadSystemParameters());
                dispatch(loadProduct(id));
            }
        
    }
    render()
    {
        let name='';
        let description='';
        if(this.props.product)
            {
                name=this.props.product.name;
                description=this.props.product.description;
            }
        return (
        
            <div className="row align-items-center justify-content-center" style={{padding: '6rem 0'}}>
                {this.props.product ?
                <>
                <Helmet>
                    <title>{name}</title>
                    <meta name="name" content={description}/>
                    <meta name="description" content={name} />
                    <meta name="theme-color" content="#E6E6FA" />
                    <meta name="robots" content="index,noarchive,nofollow" />
                    <meta name="google" content="notranslate" />
                    <meta name="viewport" content="width=device-width, initial-scale=1"/>
                </Helmet>
                <div className="card">
                    <div className="row">
                        <ProductSlider images={this.props.product.productImages}/>
                        <ProductDetailComponent product={this.props.product} userid={this.props.userid}/>
                    </div>
                </div>
                </> : <h1 className="display-4 mt-5 text-center">Ups !!! Proizvod ne postoji !!</h1>
                }
            </div>
        );
    }
}

const mapStateToProps = (state, props) =>  {

    let product = state.shop.products.find(product => product.identifier==props.id);
    if(!product && state.products!='')
    {
        product = state.products.products.find(product => product.identifier==props.id);

    }
    //const product = state.shop.products.find(product => product.id === +props.match.params.id);

    return {
        product,
        initializedState: state.shop.products ? state.shop.products.length>0:0,
        userid: state.userLogin.id
    }
};



export default connect(mapStateToProps, null)(ProductDetail);
