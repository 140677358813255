import {loadCategoriesGet,
        loadProductsGet,
        loadGroupOfProductsGet,
        addProductToCartSave,
        loadProductDetailGetAddIntoCart,
        loadProductGet,
        registerClickGet,
        loadWorkingModeGet,
        submitFormPost,
        updateSearchModeData,
        loadCategoriesForCategoryGet} from "../data/services/dataService";

import {Cookies} from 'react-cookie';
import {uid} from 'uid';

export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const INCREMENT_CART_ITEM_QUANTITY = 'INCREMENT_CART_ITEM_QUANTITY';
export const DECREMENT_CART_ITEM_QUANTITY = 'DECREMENT_CART_ITEM_QUANTITY';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_RECEIVED = 'GET_PRODUCTS_RECEIVED';
export const UPDATED_CART ='UPDATED_CART';
export const LOADFROMCOOKIE_CART ='LOADFROMCOOKIE_CART';


export const addProductToCartCreator = product => {
    return {
        type: ADD_PRODUCT_TO_CART,
        payload: product
    }
};

export const removeProductToCart = productId => {
    return {
        type: REMOVE_PRODUCT_FROM_CART,
        payload: productId
    }
};

export const loadFromCookieCartCreator = () => {
    return {
        type: LOADFROMCOOKIE_CART
    }
};

export const cartUpdated = () => {
    return {
        type: UPDATED_CART
    }
};

export function updatedCart(){
    return function (dispatch) {
        dispatch(cartUpdated());
    }
}

export function addProductToCartById(productid){
    return function (dispatch) {
        dispatch(loadProductDetailGetAddIntoCart(productid));
    }
}

export function addProductToCart(product){
    return function (dispatch) {
        dispatch(addProductToCartCreator(product));
        dispatch(addedProductToCart());
    }
}

export function addedProductToCart(){
    return function (dispatch) {
        dispatch(cartUpdated());
    }
}

export const incrementCartQuantity = productId => {
    return{
        type: INCREMENT_CART_ITEM_QUANTITY,
        payload: productId
    }
};

export const decrementCartQuantity = productId => {
  return {
      type: DECREMENT_CART_ITEM_QUANTITY,
      payload: productId
  }
};


export const ADD_BRAND_TO_FILTER = 'ADD_BRAND_TO_FILTER';
export const REMOVE_BRAND_FROM_FILTER = 'REMOVE_BRAND_FROM_FILTER';

export const addBrandToFilter = (brand, refid) => {
    return {
        type: ADD_BRAND_TO_FILTER,
        id: refid,
        brand: brand
    }
};


export const removeBrandFromFilter = (brand, refid) => {
    return  {
        type: REMOVE_BRAND_FROM_FILTER,
        id: refid,
        brand: brand
    }
};

export const ORDER_BY_ASC = 'ORDER_BY_ASC';
export const ORDER_BY_DESC = 'ORDER_BY_DESC';
export const CLEAR_ORDER_BY_PRICE = 'CLEAR_ORDER_BY_PRICE';

export const orderByAsc = () => {
    return {
        type: ORDER_BY_ASC
    }
};

export const orderByDesc =  () => {
    return {
        type: ORDER_BY_DESC
    }
};

export const clearOrderBy = () => {
    return {
        type: CLEAR_ORDER_BY_PRICE
    }
};


export const PREV_PAGE = 'PREV_PAGE';
export const NEXT_PAGE = 'NEXT_PAGE';
export const GO_PAGE = 'GO_PAGE';
export const COUNT_ITEM = 'COUNT_ITEM';


export const nextPage = () => {
    return {
        type: NEXT_PAGE
    }
};

export const prevPage = () => {
    return {
        type: PREV_PAGE
    }
};

export const goPage = (n) => {
    return {
        type: GO_PAGE,
        currentPage: n
    }
};

export const countItem = (n) => {
    return {
        type: COUNT_ITEM,
        totalItemsCount: n
    }
}

export const getProducts = (search) => {
    return {
        type: GET_PRODUCTS,
        search: search
    }
};

export const loadedProducts = (allproducts) => {
    return {
        type: GET_PRODUCTS_RECEIVED,
        products: allproducts.products,
        attributes: allproducts.attributes,
        priceRanges: allproducts.priceRanges
    }
};


export const ADD_SEARCH_ATTRIBUTE = 'ADD_SEARCH_ATTRIBUTE';
export const INVOKED_SEARCH = 'INVOKED_SEARCH';
export const REMOVE_SEARCH_ATTRIBUTE = 'REMOVE_SEARCH_ATTRIBUTE';
export const ADD_SIMPLE_SEARCH_ATTRIBUTE = 'ADD_SIMPLE_SEARCH_ATTRIBUTE';
export const REMOVE_SIMPLE_SEARCH_ATTRIBUTE = 'REMOVE_SIMPLE_SEARCH_ATTRIBUTE';

export const invokedFilterToSearch = () => {
    return {
        type: INVOKED_SEARCH
    }
};

export const addFilterToSearch = (value, id) => {
    return {
        type: ADD_SEARCH_ATTRIBUTE,
        value: value,
        id: id
    }
};

export const removeFilterFromSearch = (value, id) => {
    return {
        type: REMOVE_SEARCH_ATTRIBUTE,
        value: value,
        id: id
    }
};

export const addSimpleFilterToSearch = (value, id) => {
    return {
        type: ADD_SIMPLE_SEARCH_ATTRIBUTE,
        value: value,
        id: id
    }
};

export const removeSimpleFilterFromSearch = (value, id) => {
    return {
        type: REMOVE_SIMPLE_SEARCH_ATTRIBUTE,
        value: value,
        id: id
    }
};

export function loadProducts(search) {
    return loadProductsGet(search);
  }

  export const ADD_PRICE_TO_FILTER = 'ADD_PRICE_TO_FILTER';
  export const REMOVE_PRICE_FROM_FILTER = 'REMOVE_PRICE_FROM_FILTER';
  
  export const addPriceToFilter = (value) => {
      return {
          type: ADD_PRICE_TO_FILTER,
          priceRangeFilter:value
      }
  };
  
  
  export const removePriceFromFilter = () => {
      return  {
          type: REMOVE_PRICE_FROM_FILTER
      }
  };

  export const SYSTEM_PARAMETERS_LOADED = 'SYSTEM_PARAMETERS_LOADED';
  export const SETUP_WORKING_MODE = 'SETUP_WORKING_MODE';
  
  export const systemParametersLoaded = (mode) => {
      return {
          type: SYSTEM_PARAMETERS_LOADED,
          working_mode: mode
      }
  };

  export const setupWorkingMode = (mode) => {
    return {
        type: SETUP_WORKING_MODE,
        working_mode: mode
    }
};

  export function loadSystemParameters() {
    return loadWorkingModeGet();
  }

  export const CATEGORY_LOADED = 'CATEGORY_LOADED';
  
  export const categoryLoaded = (items) => {
      return {
          type: CATEGORY_LOADED,
          categories: items
      }
  };


  export function loadCategories(categoryid) {
    if(!categoryid)
    {
        return loadCategoriesGet();
    }
    else
    {
        return loadCategoriesForCategoryGet(categoryid);
    }
  }
  

  export const ADD_CATEGORY_TO_FILTER = 'ADD_CATEGORY_TO_FILTER';
  export const ADD_SEARCHTEXT_TO_FILTER = 'ADD_SEARCHTEXT_TO_FILTER';
  export const ADDED_CATEGORY_TO_FILTER = 'ADDED_CATEGORY_TO_FILTER';
  
  export const addCategoryToFilter = (id) => {
      return {
          type: ADD_CATEGORY_TO_FILTER,
          categoryId: id
      }
  };

  export const addSearchTextToFilter = (text) => {
    return {
        type: ADD_SEARCHTEXT_TO_FILTER,
        text: text
    }
};

export const addedCategoryToFilter = () => {
    return {
        type: ADDED_CATEGORY_TO_FILTER
    }
};

export const GET_GROUPOFPRODUCTS = 'GET_GROUPOFPRODUCTS';
export const GROUPOFPRODUCTS_RECEIVED = 'GROUPOFPRODUCTS_RECEIVED';

export const getGroupOfProducts = (category, group) => {
    return {
        type: GET_GROUPOFPRODUCTS,
        category: category,
        groupid: group
    }
};

export const groupOfProductsReceived = (products) => {
    return {
        type: GROUPOFPRODUCTS_RECEIVED,
        products: products
    }
};


export function loadGroupOfProducts(category, group) {
    return loadGroupOfProductsGet(category, group);
  }

  export const LOGIN_USER = 'LOGIN_USER';
  
  export const loginUserCreator = (id) => {
      return {
          type: LOGIN_USER,
          userid: id
      }
  };

  export function LoginUserCookie()
  {
    return function (dispatch) {
        const COOKIE_USER='eWindow_user';
        const COOKIE_EXPIREDDATES=30;

        const cookies = new Cookies();
        console.log(cookies.get(COOKIE_USER));
        let eWindowUser=cookies.get(COOKIE_USER);
        if(!eWindowUser)
        {
            eWindowUser=uid(15);
            var expiredCookieDate = new Date();
            expiredCookieDate.setDate(expiredCookieDate.getDate()+COOKIE_EXPIREDDATES);
            cookies.set(COOKIE_USER, eWindowUser, { path: '/', expires: expiredCookieDate});
            console.log(cookies.get(COOKIE_USER));
        }
        
        dispatch(loginUserCreator(eWindowUser));
    }

  }

  export function submitForm(postedForm) {
    return submitFormPost(postedForm);
  }

  export const FORM_POSTED = 'FORM_POSTED';

  export const formPosted = () => {
    return {
        type: FORM_POSTED
    }
};

  export const CLICK_REGISTERED = 'CLICK_REGISTERED';

  export const clickRegistered = () => {
    return {
        type: CLICK_REGISTERED
    }
};

  export function registerClick(link, userid,productid, vendorid) {
    return registerClickGet(link, userid,productid, vendorid);
  }

  export const SEARCH_MODE = 'SEARCH_MODE';

  export const searchMode = (mode) => {
    return {
        type: SEARCH_MODE,
        mode: mode
    }
};
    export function updateSearchMode(mode) {
        return updateSearchModeData(mode);
  }

  export const GET_PRODUCT = 'GET_PRODUCT';
  export const GET_PRODUCT_LOADED = 'GET_PRODUCT_LOADED';

    export const getLoadingProduct = (id) => {
        return {
            type: GET_PRODUCT,
            productid: id
        }
    };

    export const getProductReceived = (product) => {
        return {
            type: GET_PRODUCT_LOADED,
            product: product
        }
    };

    export function loadProduct(id) {
        return loadProductGet(id);
      }