import React, {Component} from 'react';
import {connect} from 'react-redux';
import './BrandFilter.scss';
import {addBrandToFilter, removeBrandFromFilter} from "../../actions";

import {uid} from "uid";

const BrandFilter = (props) => {

    const {dispatch, brandItemsCount, brands} = props;
    const handleSelectBox = (e) => {
        const name = e.target.name;
        const refid=e.target.title;
        const value = e.target.checked;

        if(e.target.checked) {
            dispatch(addBrandToFilter(name, refid));
        } else {
            dispatch(removeBrandFromFilter(name,refid));
        }
    };


        return (
            <>
            { brands.length>0 && 
            <div className="card mb-3 card__brand">
                <div className="card-header">
                    <h3>Marke</h3>
                </div>
                
                <ul className="list-group flex-row flex-wrap overflow-auto">
                    {brands.map(brand => (
                        <li className="list-group-item list-group-item__brand flex-50" key={uid(15)}>
                            <label className="custom-checkbox text-capitalize label__brand"> {brand.name} ({brandItemsCount[brand.name]})
                                <input type="checkbox" key={brand.name}
                                       name={brand.name}
                                       title={brand.id}
                                       className="custom-checkbox__input" onInput={handleSelectBox} key={uid(15)}/>
                                <span className="custom-checkbox__span"></span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
                }
            </>
        );

};

const mapStateToProps = (state) => {

    const brandItemsCount = {};
    const brands=[];

    if(state.attributes.searchAttributes)
    {
        state.attributes.searchAttributes.forEach(p => {
            if(p.type=="brand")
                p.values.forEach(x=>{
                    brandItemsCount[x] = brandItemsCount[x] + 1 || 1;
                    brands.push({"name": x, "id":p.refId});
                }
                );
                
        });
    }
    /*state.shop.products.forEach(p => {
        brandItemsCount[p.brand] = brandItemsCount[p.brand] + 1 || 1;
    });*/


    return {
        brandItemsCount,
        brands
    }

};

export default connect(mapStateToProps)(BrandFilter);