import {CLICK_REGISTERED} from "../actions";

export const  clickRegisteredReducer = (state='', action) => {
    switch (action.type) {
        case CLICK_REGISTERED:
                return { ...state, registered:true };
        default:
            return state;
    }
};

