import {categoryLoaded,
        getProducts,
        loadedProducts,
        getGroupOfProducts,
        groupOfProductsReceived,
        addProductToCartCreator,
        getProductReceived,
        clickRegistered,
        formPosted,
        systemParametersLoaded,
        setupWorkingMode} from "../../actions";
import {Cookies} from 'react-cookie';

export function loadCategoriesGet() {
  return function (dispatch) {
    const SERVER_URL = process.env.NODE_ENV === 'production' ? window.env.SERVER_URL : window.env.REACT_APP_SERVER_URL;
    //dispatch(categoryLoaded());
    return fetch(`${SERVER_URL}api/Categories`,{redirect: 'follow'})
      .then(
      response => response.json(),
      error => console.log('An error occurred.', error),
    )
      .then((json) => {
        //console.log('JSON:',JSON.stringify(json));
        if(!json)
          json=[];
        
        dispatch(categoryLoaded(json));
      },
    );
  };
}

export function loadCategoriesForCategoryGet(categoryid) {
  return function (dispatch) {
    const SERVER_URL = process.env.NODE_ENV === 'production' ? window.env.SERVER_URL : window.env.REACT_APP_SERVER_URL;
    //dispatch(categoryLoaded());
    return fetch(`${SERVER_URL}api/Categories/subcategories?categoryId=${categoryid}`,{redirect: 'follow'})
      .then(
      response => response.json(),
      error => console.log('An error occurred.', error),
    )
      .then((json) => {
        //console.log('JSON:',JSON.stringify(json));
        if(!json)
          json=[];
        
        dispatch(categoryLoaded(json));
      },
    );
  };
}



export function loadWorkingModeGet() {
  return function (dispatch) {
    const SERVER_URL = process.env.NODE_ENV === 'production' ? window.env.SERVER_URL : window.env.REACT_APP_SERVER_URL;
    //dispatch(categoryLoaded());
    return fetch(`${SERVER_URL}api/SystemParameters`,{redirect: 'follow'})
      .then(
      response => response.json(),
      error => console.log('An error occurred.', error),
    )
      .then((json) => {
        //console.log('JSON:',JSON.stringify(json));
        if(!json)
          json=[];
        
        dispatch(systemParametersLoaded(json));
      },
    );
  };
}




export function loadGroupOfProductsGet(category, group) {
  return function (dispatch) {
    dispatch(getGroupOfProducts(category, group));
    
    const SERVER_URL = process.env.NODE_ENV === 'production' ? window.env.SERVER_URL : window.env.REACT_APP_SERVER_URL;
    
    return fetch(`${SERVER_URL}api/ProductsCategory/`+category+"/"+group,{redirect: 'follow'})
      .then(
      response => response.json(),
      error => console.log('An error occurred.', error),
    )
      .then((json) => {
        console.log('JSON:',JSON.stringify(json));
        dispatch(groupOfProductsReceived(json));
      },
    );
  };
}

export function loadProductsGet(search) {
  return function (dispatch) {
    dispatch(getProducts(search));
    

    //dispatch(loadCartGet()); 
    if(!search)
      search= {};
    
    const SERVER_URL = process.env.NODE_ENV === 'production' ? window.env.SERVER_URL : window.env.REACT_APP_SERVER_URL;
    let newSearchAttributes=[];
    if(typeof search.searchFilter !=='undefined')
      search.searchFilter.map(x=>newSearchAttributes.push({name:x.ref.split("_")[0], type:x.ref.split("_")[1] , values:x.values, valueContent: x.valueContent}));

    const request=JSON.stringify({attributes: newSearchAttributes,categoryId:search.category, Criterion:search.text, priceRange:search.priceRange});
    return fetch(`${SERVER_URL}api/Products`,{method: 'POST',body: request, redirect: 'follow', headers: {
      'Content-Type': 'application/json',
      'userid': `${search.userid}`
    }})
      .then(
      response => response.json(),
      error => console.log('An error occurred.', error),
    )
      .then((json) => {
        //console.log('JSON:',JSON.stringify(json));
        if(!json)
          json={};

        dispatch(loadedProducts(json));
      },
    );
  };
};

export function updateSearchModeData(mode) {
  return function (dispatch) {
    
    const SERVER_URL = process.env.NODE_ENV === 'production' ? window.env.SERVER_URL : window.env.REACT_APP_SERVER_URL;
    
    return fetch(`${SERVER_URL}api/SystemParameters/searchmode?workingMode=${mode}`,{method: 'POST', redirect: 'follow', headers: {
      'Content-Type': 'application/json'
    }})
      .then(
      response => response,
      error => console.log('An error occurred.', error),
    )
      .then(() => {
        //console.log('JSON:',JSON.stringify(json));
        
        dispatch(setupWorkingMode(mode));

      }
    );
  };
};

export function submitFormPost(form) {
  return function (dispatch) {

    
    const SERVER_URL = process.env.NODE_ENV === 'production' ? window.env.SERVER_URL : window.env.REACT_APP_SERVER_URL;
    
    return fetch(`${SERVER_URL}api/Form/`,{method: 'POST',body: form, redirect: 'follow', headers: {
      'Content-Type': 'application/json'
    }})
      .then(res => {
        if(res.status >= 400) {
          throw new Error("Server responds with error!");
      }
      dispatch(formPosted());
      }
    );
  };
};

export function registerClickGet(link, userid, productid, vendorid) {
  return function (dispatch) {

    
    const SERVER_URL = process.env.NODE_ENV === 'production' ? window.env.SERVER_URL : window.env.REACT_APP_SERVER_URL;
    
    const request=JSON.stringify({link: link,userId:userid, productId:productid, vendorId:vendorid});
    return fetch(`${SERVER_URL}api/ClickRedirection/`,{method: 'POST', redirect: 'follow', body: request,headers: {
      'Content-Type': 'application/json'
    }})
      .then(res => {
        if(res.status >= 400) {
          throw new Error("Server responds with error!");
      }
      dispatch(clickRegistered());
      }
    );
  };
};

export function loadProductDetailGetAddIntoCart(productid) {
  return function (dispatch) {
    if(!productid)
      productid= "";

    
    const SERVER_URL = process.env.NODE_ENV === 'production' ? window.env.SERVER_URL : window.env.REACT_APP_SERVER_URL;
    
    return fetch(`${SERVER_URL}api/Products/`+productid, {redirect: 'follow'})
      .then(
      response => response.json(),
      error => console.log('An error occurred.', error),
    )
      .then((json) => {
        if(!json)
          json={};

        dispatch(addProductToCartCreator(json));
      },
    );
  };
};

export function loadProductGet(productid) {
  return function (dispatch) {
    if(!productid)
      productid= "";

    
    const SERVER_URL = process.env.NODE_ENV === 'production' ? window.env.SERVER_URL : window.env.REACT_APP_SERVER_URL;
    
    return fetch(`${SERVER_URL}api/Products/`+productid, {redirect: 'follow'})
      .then(
      response => response.json(),
      error => console.log('An error occurred.', error),
    )
      .then((json) => {
        if(!json)
          json={};

        dispatch(getProductReceived(json));
      },
    );
  };
};

export function loadCartGet(){
  return function (dispatch) {
    
    const cartProducts=loadCartFromCookie();
    if(cartProducts)
      cartProducts.map(x=>dispatch(loadProductDetailGetAddIntoCart(x)));
    
  }
}

function loadCartFromCookie()
{
  const cookies = new Cookies();
    const eWindowUser=getUserFromCookie()

    const COOKIE_CART=eWindowUser+'_CART';
    const cartProducts=cookies.get(COOKIE_CART);
    return cartProducts;
}

function getUserFromCookie()
{
  const cookies = new Cookies();
    const COOKIE_USER='eWindow_user';
    const eWindowUser=cookies.get(COOKIE_USER);
    return eWindowUser;
}

export function addProductToCartSave(cart) {
    const cookies = new Cookies();
    const eWindowUser=getUserFromCookie()
    const COOKIE_CART=eWindowUser+'_CART';
    const COOKIE_EXPIREDDATES=10;

    var expiredCookieDate = new Date();
    expiredCookieDate.setDate(expiredCookieDate.getDate()+COOKIE_EXPIREDDATES);
    const cartProducts=loadCartFromCookie();
    let cartCookie=[];
    cart.map(x=>cartCookie.push(x.id));
    
    //cart.map(x=>cartProducts.findIndex(item => item === x.id)<0? cartCookie.push(x.id):null);
     
    cookies.set(COOKIE_CART, cartCookie, { path: '/', expires: expiredCookieDate});
}