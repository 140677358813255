import {LOGIN_USER} from "../actions";

export const  userLoginReducer = (state='', action) => {
    switch (action.type) {
        case LOGIN_USER:
                return { ...state, id: action.userid };
        default:
            return state;
    }
};

