import {SYSTEM_PARAMETERS_LOADED,SETUP_WORKING_MODE } from "../actions";

const initialState = {
    workingMode: 0
};

export const  systemParametersReducer = (state=initialState, action) => {
    switch (action.type) {
        case SYSTEM_PARAMETERS_LOADED:
            return { ...state, workingMode: action.working_mode};
        case SETUP_WORKING_MODE:
            return {...state, workingMode: action.working_mode};
        default:
            return state;
    }
};

