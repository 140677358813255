import React from 'react';
import {connect} from 'react-redux';
import "./AttributeBool.scss"
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {addSimpleFilterToSearch, removeSimpleFilterFromSearch} from "../../../actions";


function AttributeBool(props){
  const {dispatch} = props;
 
    let isChecked = props.checked;
    const [state, setState] = React.useState(isChecked);
    
      const handleChange = (event) => {

        const name = props.name;
        const refid=props.id;

        setState({ ...state, isChecked: event.target.checked });//[event.target.name]

        if(!event.target.checked) {
          dispatch(removeSimpleFilterFromSearch(event.target.checked.toString(), refid));
        } else {
            dispatch(addSimpleFilterToSearch(event.target.checked.toString(),refid));
        };
      }
    return (
      <div className="list-group-item flex-50" key={props.id}>
                 <FormControlLabel
                    control={<Switch checked={state.isChecked} onChange={handleChange} name="checkedA" key={props.id}/>}
                    label={props.name} title={props.id} key={props.id} style={{color: props.obsolete ? `red`:`black`}}
                />
        </div>       
    )
}

export default connect()(AttributeBool);