import {CATEGORY_LOADED} from "../actions";
import {categories} from "../data/categories";

const initialState = {
    categories: categories
};

export const  categoryReducer = (state='', action) => {
    switch (action.type) {
        case CATEGORY_LOADED:
                var index;
                let menuCategories=[];
                for (index = 0; index < action.categories.length; ++index) {
                    let cat=action.categories[index];
                    cat.children.push({referenceid:cat.referenceid, title:"Sve..."});
                    menuCategories.push(cat);
                };
                
                return { ...state, categories: menuCategories, loading: false };
        default:
            return state;
    }
};

