import React, { Component } from "react";
import queryString from 'query-string';
import {connect} from 'react-redux';

import {registerClick} from "../../actions";

const RedirectOnServer = (props) => {
  
    const  parsed= queryString.parse(window.location.search);
    const target = parsed.url;
    const userid=parsed.id;
    const productid=parsed.productid;
    const vendorid=parsed.vendor;
    const registerClickInternal=props.registerClickInternal;
    if(props.registered)
      window.location.replace(target);
    else
     registerClickInternal(target,userid,productid, vendorid);


  const reloadurl=()=>{
    window.location.replace(target);
  }
  
  return (
    <div>
      <h1 className="display-4 mt-5 text-center">Učitavanje sajta prodavca !!</h1>
    </div>
  );
  
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return { registerClickInternal: (userid, link, productid, vendorid) => dispatch(registerClick(userid, link, productid, vendorid))}  
}

const mapStateToProps = state => {
  
  return {registered: state.click.registered}
};

export default connect(mapStateToProps,mapDispatchToProps)(RedirectOnServer);