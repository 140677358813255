import React, {Component} from 'react';
import AttributesFilter from '../../components/Attributes/AttributesFilter/AttributesFilter';
import BrandFilter from "../../components/BrandFilter/BrandFilter";
import OrderFilter from "../../components/OrderFilter/OrderFilter";
import PriceFilter from "../../components/PriceFilter/PriceFilter";

class FilterBar extends Component {
    render() {
        return (
            <div className="col-lg-2 ">
                <div className="row">
                    <div className="col-12">
                        <BrandFilter/>
                    </div>
                    <div className="col-12">
                        <AttributesFilter minPriority="0" maxPriority="30"/>
                    </div>
                    <div className="col-12">
                        <PriceFilter/>
                    </div>
                    {/*<div className="col-12">
                        <OrderFilter/>
                    </div>*/}
                </div>
            </div>
        );
    }
}

export default FilterBar;