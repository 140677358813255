import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {formatMoney} from "../../pipes/priceFormatter";
import {cumulativeOffSet} from "../../utilities/cumulativeOffset";

import './Product.scss';
import SlideDots from "../SlideDots/SlideDots";
import {addProductToCart} from "../../actions";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Badge } from 'react-bootstrap'
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const Product = (props) => {

    const {
        title,
        price,
        productImages,
        description,
        name,
        summaryOffer,
        identifier,
        group
    } = props.product;
    
    let assignedGroup=group;
    if(!props.selectedCategory)
        assignedGroup=undefined

    const imageRef = React.createRef();
    let [img, setImg] = ["NaN"];
    if(productImages)
        [img, setImg] = useState(productImages[0]);

    const [aItem, setAItem] = useState(0);

    const handleImageChange = (e) => {

        let  clientX;

        if(e.type === 'touchmove') {
            clientX = e.touches[0].clientX;
        } else {
            clientX = e.clientX;
        }

        const currentX = clientX - cumulativeOffSet(imageRef.current).left;

        // console.dir(imageRef.current);

        const part = imageRef.current.clientWidth / productImages.length;
       // console.log(Math.ceil(currentX / part) - 1);

        let imgIndex = Math.ceil(currentX / part) - 1;
        if (imgIndex < 0) {
            imgIndex = 0;
        }

        if (imgIndex >= productImages.length) {
            imgIndex = productImages.length - 1;
        }
        setAItem(imgIndex);
        setImg(productImages[imgIndex]);
    };

    const handleMouseOut = (e) => {
        setImg(productImages[0]);
        setAItem(0);
    };

    const changeImage = (i) => {
        setImg(productImages[i]);
        setAItem(i);
    }


    return (
        <div className="card h-100 product">
            <Link to={`/productid?id=${identifier}`} className="product__link">
                <div>
                {productImages &&
                <img className="card-img-top product__img" src={productImages[0]} alt={title} ref={imageRef}>

                </img>
                }
                 
                {/*assignedGroup &&
                <>
                
                <Link to={`/group/${group}`} className="product__link">
                <div class="product__group"><Badge pill variant="secondary">Slicnih: {group}</Badge>{' '}</div>
                    </Link>
                    </>
                */}
                </div>
                {/*<SlideDots len={images.length} activeItem={aItem} changeItem={changeImage}/>*/}
            </Link>
            <div className="card-body product__text">
                <h4 className="card-title product__title">
                    <Link to={`/products/${identifier}`}>{title}</Link>
                </h4> 
                <p className="card-text product__name">{ ReactHtmlParser(name.substring(0,130)) }</p>
                {
                    summaryOffer &&
                    <p className="card-text product__name">Cene: {summaryOffer.priceRange.formattedValue}</p>
                }
                {/*<p className="card-text product__description">{ ReactHtmlParser(description.substring(0,130)) }</p>*/}
                <button
                    onClick={() => {
                        props.dispatch(addProductToCart({...props.product}))
                    }}
                    className="btn btn-info product__add-to-cart">Uporedi
                </button>
            </div>
        </div>
    );
};

export default connect()(Product);

