import React from 'react'
import { connect } from 'react-redux'
import { searchMode,updateSearchMode } from '../../actions'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


const ModeSelect = (props) => {
//export default function ModeSelect(props) {
  const classes = useStyles();
  const [age, setAge] = React.useState(props.mode);

  const handleChange = (event) => {
    const searchModeValue = event.target.value;
    setAge(searchModeValue);
    props.dispatch(searchMode(searchModeValue));
    props.dispatch(updateSearchMode(searchModeValue));
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Mod pretrage</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          onChange={handleChange}
        >
          <MenuItem value={0}>Sužavanje izbora</MenuItem>
          <MenuItem value={1}>Svi koji imaju osobinu</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default connect()(ModeSelect);

