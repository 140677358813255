import React from 'react';
import {connect} from 'react-redux';
import FilterBar from "../../containers/FilterBar/FilterBar";
import FilterBarRight from "../../containers/FilterBarRight/FilterBarRight";
import ProductList from "../../containers/ProductList/ProductList";
import Pagination from "../../components/Pagination/Pagination";

import {addCategoryToFilter,loadSystemParameters, loadCategories} from "../../actions";

import {trackGA} from "../../utilities/gaUtility";
import ReactGA from 'react-ga';

import LogRocket from 'logrocket';
const Home = (props) => {

    const GA = process.env.NODE_ENV === 'production' ? window.env.REACT_GOOGLE_ANALYTICS : window.env.GOOGLE_ANALYTICS;
    if(process.env.NODE_ENV === 'production')
    {
        ReactGA.initialize(GA);
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }

    trackGA(ReactGA,window.location.pathname + window.location.search)

    if(process.env.NODE_ENV === 'production')
        {
            LogRocket.init('toboci/ewindow');
        }
    
    if(props.categoryid)
    {
        props.dispatch(addCategoryToFilter(props.categoryid));
        
    }
    else
    {
        if(props.predefinedcategoryid)
        {
            props.dispatch(loadCategories(props.predefinedcategoryid));
            props.dispatch(loadSystemParameters());
        }
        else
        {
            props.dispatch(loadCategories());
            props.dispatch(loadSystemParameters());
        }
        //props.dispatch(loadProducts());
    }

    return (
        <React.Fragment>
                <div className="row">
                    <FilterBar/>
                    <ProductList/>
                    <FilterBarRight/>
                </div>
        </React.Fragment>
    );
};

  const mapStateToProps = (state, props) =>  {

    let categoryselected = props.id;
    const predefinedcategoryid = process.env.NODE_ENV === 'production' ? window.env.CATEGORY_SPECIALIZATION : window.env.CATEGORY_SPECIALIZATION;
    
    const search = props.search;
    let initialload = true;
    //const categoryid = state.categories.categories.find(category => category.title === categoryselected);
    const categoryid=findcategoryidbyid(state.categories.categories,categoryselected);
    if(typeof categoryid !== 'undefined')
    {
        initialload = false;
        return {
            categoryid,
            search,
            predefinedcategoryid,
            initialload
        }
    }
    else
        {
            if(predefinedcategoryid !== '')
            {
                return { predefinedcategoryid }
            }
            else
            {
                return { initialload }
            }
        }

};

//export default Home;

export default connect(mapStateToProps, null)(Home);

const CustomMenu = ()=>{
        return(
            <div>
            <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
            <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                <div class="sidebar-brand-icon rotate-n-15">
                    <i class="fas fa-laugh-wink"></i>
                </div>
                <div class="sidebar-brand-text mx-3">eIzlog</div>
            </a>
            <hr class="sidebar-divider my-0"/>

            <li class="nav-item active">
                <a class="nav-link" href="index.html">
                    <i class="fas fa-fw fa-tachometer-alt"></i>
                    <span>Dashboard</span></a>
            </li>

            <hr class="sidebar-divider"/>

            <div class="sidebar-heading">
                Interface
            </div>
            <li class="nav-item">
                <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseTwo">
                    <i class="fas fa-fw fa-cog"></i>
                    <span>Components</span>
                </a>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Custom Components:</h6>
                        <a class="collapse-item" href="buttons.html">Buttons</a>
                        <a class="collapse-item" href="cards.html">Cards</a>
                    </div>
                </div>
                </li>
            </ul>
            </div>
        )
    }

function findcategoryidbytitle(arr, title)
{
    if(typeof title==='undefined' || typeof arr==='undefined')
        return;

    if(!arr)
        return;

    let foundid;

    const itemIndex = arr.findIndex(item => item.url === title);

    if(itemIndex < 0) {

        arr.map(x=>typeof foundid==='undefined'?foundid=findcategoryidbytitle(x.children, title):foundid);

        return foundid;
    } else 
        return arr[itemIndex].id;
    
}

function findcategoryidbyid(arr, id)
{
    if(typeof id==='undefined' || typeof arr==='undefined')
        return;

    if(!arr)
        return;

    let foundid;

    const itemIndex = arr.findIndex(item => item.referenceid === id);

    if(itemIndex < 0) {

        arr.map(x=>typeof foundid==='undefined'?foundid=findcategoryidbyid(x.children, id):foundid);

        return foundid;
    } else 
        return arr[itemIndex].referenceid;
    
}
