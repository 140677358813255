
import React, {Component} from 'react';
import AttributesFilter from '../../components/Attributes/AttributesFilter/AttributesFilter';
import ButtonSearch from "../ButtonSearch";
import SearchMode from "../SearchMode/SearchMode";

class FilterBarRight extends Component {
    render() {
        return (
            <div className="col-lg-2">
                <div className="row">
                    <div className="col-12">
                        <SearchMode/>
                        <ButtonSearch/>
                        <AttributesFilter minPriority="31" maxPriority="100"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default FilterBarRight;