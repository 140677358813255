import React, { Component } from 'react';
import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore } from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk'

import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';

import './App.scss';
import Home from "./pages/Home/Home";
import Header from "./components/Header/Header";
import MenuTop from "./components/MenuTop/MenuTop";
import Footer from "./components/Footer/Footer";
import ProductDetail from "./pages/ProductDetail/ProductDetail";
import ProductGroup from "./pages/ProductGroup/ProductGroup";
import ShoppingCart from "./pages/ShopingCart/ShoppingCart";
import CookieLogin from "./components/CookieLogin/CookieLogin";
import ContactForm from "./components/ContactForm/ContactForm";


import { Helmet } from "react-helmet";
import queryString from 'query-string';

import RedirectOnServer from './components/ProductDetail/RedirectOnServer';

let composeEnhancer={};

if (process.env.NODE_ENV !== 'production') {
    composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}
else
    composeEnhancer=compose;

export const store = createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(thunk)),
);

//export const  store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), applyMiddleware(dataService));
const WrapperComponent = (props) => {
    const  parsed= queryString.parse(window.location.search);
    const location=window.location.pathname;
    if(location.toLowerCase().includes("categoryid"))
    {
        if (parsed.id){return <Home {...props} id={parsed.id}/>}
    }
    if(location.toLowerCase().includes("productid"))
    {
        if (parsed.id){return <ProductDetail {...props} id={parsed.id}/>}
    }
    else
    return <Home/>;
 }

class App extends Component {
    
  render() {
    return (
        <div className="App">
        <Helmet>
        <html lang="en" />
        <title>eIzlog</title>
        <meta name="description" content="eIzlog mesto gde možete naći i uporediti bilo šta" />
        <meta name="theme-color" content="#E6E6FA" />
      </Helmet>
        <Provider store={store}>
            {/*<CookieBanner
                message="eIzlog web sajt koristi kolačiće (eng. Cookies). Ovaj sajt koristi kolačiće u cilju boljeg korisničkog iskustva, personalizacije sadržaja, pružanja funkcije društvenih medija i analiziranja saobraćaja. Uvek možete promeniti ova podešavanja klikom na Postavke kolačića na dnu strane. Za više detalja, posetite Politiku Privatnosti."
                necessaryOptionText="Neophodni"
                preferencesOptionText="Preferencije"
                statisticsOptionText="Statistika"
                privacyPolicyLinkText="Politika privatnosti"
                acceptButtonText="Prihvatam"
                showMarketingOption={false}
                wholeDomain={true}
                dismissOnScroll={true}
                onAccept = {() => {}}
                onAcceptPreferences = {() => {}}
            />*/}
            <CookieLogin/>
            <BrowserRouter>
            <React.Fragment>
                <>
                <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                <MenuTop/>
                <Switch>
                    
                    <Route path={'/productid'} component={WrapperComponent}/>
                    <Route path={'/categoryid'} component={WrapperComponent}/>
                    <Route exact path={'/'} render={() => {
                        return <Redirect to={'/products'}/>
                    }}/>
                    <Route exact path={'/products'} component={Home}/>
                    <Route exact path={'/category/:id'} component={Home}/>
                    <Route exact path={'/group/:id'} component={ProductGroup}/>
                    <Route exact path={'/cart'} component={ShoppingCart}/>
                    <Route exact path={'/contact'} component={ContactForm}/>
                    <Route path="/navigate"
                        component={RedirectOnServer}
                        target="/someexternaltargetstring like cnn.com"
                        />
                    <Route path={'/'} component={WrapperComponent}/>
                </Switch>
                {/*<Footer/>*/}
                </div></div>
                </>
            </React.Fragment>
            </BrowserRouter>
        </Provider>
        </div>
    );

  
  }
}

export default App;
