import React from 'react';
import {connect} from 'react-redux';
import {formatMoney} from "../../pipes/priceFormatter";
import CartItem from "../../components/CartItem/CartItem";

import { Tabs, Tab } from 'react-bootstrap'

import ProductDetailComponent from '../../components/ProductDetail/ProductDetail';
import ProductSlider from "../../components/ProductSlider/ProductSlider";

import {removeProductToCart,updatedCart} from "../../actions";

import {uid} from "uid";

class ShoppingCart extends React.Component {

    removeItem = (id) => {
        this.props.dispatch(removeProductToCart(id));
        this.props.dispatch(updatedCart());
    };

    render(){
        return(
            <div className="row">
        <div className="align-items-center justify-content-center" style={{padding: '4em 0'}}>
            {this.props.cartItems[0] ?
                <Tabs defaultActiveKey={this.props.defaultTab} id="tab-products" aria-label="wrapped label tabs">
                    {this.props.cartItems.map(x=>{
                        return (<Tab eventKey={x.identifier+'-'+this.props.uniqueid} title={x.name} wrapped>
                            <div class="col">
                                <div class="row">
                                <ProductSlider images={x.productImages}/>
                                <ProductDetailComponent product={x} type="Cart" removeItem={this.removeItem}/>
                                </div>
                            </div>
                        </Tab>)
                    }
                    )}
                        
                    </Tabs>
            : <div class="col-lg-12"><h1>Nema selektovanog proizvoda za uporedjivanje !!</h1></div> }
        </div>
        </div>
        )}
};


const mapStateToProps = state => {

    console.log(state, 'state has changed');
    let defaultTab='';
    const uniqueId=uid(15);
    if(state.shop.cart)
    {
        if(state.shop.cart.length>0)
            defaultTab=state.shop.cart[0].identifier+'-'+uniqueId;
    }

    return {
        cartItems: state.shop.cart,
        cartItemCount: state.shop.cart.reduce((count, curItem) => {
            return count + curItem.quantity;
        }, 0),
        totalPrice: state.shop.cart.reduce((count, curItem) => {
            return count + (curItem.price * curItem.quantity);
        }, 0),
        defaultTab: defaultTab,
        uniqueid:uniqueId
    }
}

export default connect(mapStateToProps, null)(ShoppingCart);
