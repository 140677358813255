import React, {Component} from 'react';
import {connect} from 'react-redux';
import './PriceFilter.scss';
import {addPriceToFilter, removePriceFromFilter} from "../../actions";
import { InputGroup,FormControl } from 'react-bootstrap'

const PriceFilter = (props) => {

    const {dispatch, priceRange} = props;
    let priceFilter={};
    
    priceFilter.minValue=0;
    priceFilter.maxValue=0;
    priceFilter.currencyIso=priceRange.currencyIso;

    const priceChangeMaxValueEvent = (event) =>
    {
      priceFilter.maxValue=parseFloat(event);
      dispatch(addPriceToFilter(priceFilter))
    }

    const priceChangeMinValueEvent = (event) =>
    {
      priceFilter.minValue=parseFloat(event);
      dispatch(addPriceToFilter(priceFilter))
    }


        return (
          <>
          {priceRange.currencyIso && 
            <div className="card mb-3">
                <div className="card-header">
                    <h3>Cene</h3> 
                </div>
                  <div>Od:<InputGroup size="sm"  className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-min">{priceRange.currencyIso}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl aria-label="Iznos" placeholder={priceRange.from} aria-label="Iznos" aria-describedby="inputGroup-min"
                  onChange = {(event) => priceChangeMinValueEvent(event.target.value )}/>
                 
                </InputGroup></div>
                <div>Do:<InputGroup size="sm" className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-max">{priceRange.currencyIso}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl aria-label="Iznos" placeholder={priceRange.to} aria-label="Iznos" aria-describedby="inputGroup-max" 
                  onChange = {(event) => priceChangeMaxValueEvent(event.target.value )}/>
                
                </InputGroup>
                </div>
                
            </div>
            }
            </>
        );

};

const mapStateToProps = (state) => {

    const brandItemsCount = {};
    const prices=[];
    let priceRange={};

    if(state.products.priceRanges)
    {
        state.products.priceRanges.forEach(p => {
              if(p.currencyIso==="RSD")
              {
                priceRange.from=parseInt(p.minValue);
                priceRange.to=parseInt(p.maxValue);
                priceRange.currencyIso=p.currencyIso;
              }
                
        });
    }
    /*state.shop.products.forEach(p => {
        brandItemsCount[p.brand] = brandItemsCount[p.brand] + 1 || 1;
    });*/


    return {
        priceRange
    }

};

export default connect(mapStateToProps)(PriceFilter);