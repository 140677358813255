import React,{setValue} from 'react';
import "./AttributeRange.scss"
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
  root: {
    width: "max-content",
  },
});

function valuetext(value) {
  return `${value}`;
}

function AttributeRange(props){
    const classes = useStyles();
   
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };  
    
    let minValue=0;
      let maxValue=10; 

      props.attributes.forEach(element => {
          if(minValue>parseInt(element))
            minValue=parseInt(element);
          if(maxValue<parseInt(element))
            maxValue=parseInt(element);
        

      });

      const [value, setValue] = React.useState([minValue, maxValue]);
    return (
      <div className="list-group-item flex-50" key={props.id}>
            <p>{props.name}</p>
            <div key={props.name}>
            <Slider
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
                maxValue={maxValue}
                key={props.id}
            />
            </div>
        </div>       
    )
}

export default AttributeRange;