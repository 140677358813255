import React, {Component} from 'react';
import {connect} from 'react-redux';
import './MenuTop.scss';
import { Navbar,NavDropdown,Form,FormControl,Button,Toast, Row, Col  } from 'react-bootstrap'

import {NavLink} from 'react-router-dom';
import ReactNestedMenuNav from '../../components/ReactNestedMenuNav/ReactNestedMenuNav';

import ReactGA from 'react-ga';

import {addCategoryToFilter,addSearchTextToFilter,getProducts,loadedProducts, loadProducts} from "../../actions";

const MenuTop = (props, state) => {

    const {dispatch, categoriesArray, searchText, search} = props; 

    const handleChange = (eventKey, syntheticEvent) => {
        const name = eventKey;
        ReactGA.set({ dimension1: name });
        dispatch(addCategoryToFilter(name));

        /*if(e.target.checked) {
            dispatch(addBrandToFilter(name, refid));
        } else {
            dispatch(removeBrandFromFilter(name,refid));
        }*/
    }; 

    const handleSearchClick =(value) => {
        dispatch(addSearchTextToFilter(value))
    };


    const handleSearchRequestOnEnterClick=(value,e) => {
        if (e.charCode === 13) { 
            e.preventDefault();
            dispatch(getProducts(props.search));
            dispatch(loadProducts(props.search));
        }
    };

        
 
        return (
        <div><Navbar bg="light" expand="lg">
        <Navbar.Brand href="/"><img src="eIzlog.png" width="50px" height="50px"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        
        {categoriesArray && 
          <ReactNestedMenuNav
                navParentClassname="vertical menu nested"
                navTopLevelParentClassname="vertical menu"
                navChildClassname="childs"
                navChildElementClassname="has-child"
                menuData={categoriesArray}
                trigger={handleChange}
                searchClick={handleSearchClick}
                searchOnEnterClick={handleSearchRequestOnEnterClick}
              />
        }
        <NavLink className="nav-link" to="/cart"><i className="fa fa-shopping-cart mr-2"
                                                                          aria-hidden="true" />Uporedi {props.cartLength ? `(${props.cartLength})`: ''}</NavLink>
        
            
        </Navbar>
        
        </div>
        );
}


const mapStateToProps = (state, props) => {

    const brandItemsCount = {};
    let categoriesArray=state.categories.categories; 

    /*state.categories.categories.forEach(p => {
        categoriesArray.push(p.title);
    });*/

    

    return {
        categoriesArray,
        searchText:state.text,
        search:state.search,
        cartLength: state.shop.cart.length,
        cartUpdated: state.shop.cartUpdated
    }

};




export default connect(mapStateToProps)(MenuTop);

