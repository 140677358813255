import React, {Component} from 'react';
import {connect} from 'react-redux';
import ProductDetailComponent from '../../components/ProductDetail/ProductDetail';
import ProductSlider from "../../components/ProductSlider/ProductSlider";

import {loadGroupOfProducts} from "../../actions";
import { Tabs, Tab } from 'react-bootstrap'

import ReactGA from 'react-ga';
import {trackGA} from "../../utilities/gaUtility";

class ProductGroup extends Component {
//const ProductGroup = (props,state) => {
    
    componentDidMount() {
        //const {fetchProducts} = this.props;
        //console.log(props);
        this.props.dispatch(loadGroupOfProducts(this.props.category, this.props.group));

        trackGA(ReactGA,window.location.pathname + window.location.search);
    }

    render() {
        
        return(
            <div className="row align-items-center justify-content-center" style={{padding: '4em 0'}}>
            {this.props.products[0] &&
                <Tabs defaultActiveKey={this.props.defaultTab} id="tab-products">
                    {this.props.products.map(x=>{
                        return (<Tab eventKey={x.name} title={x.name}>
                            <div className="row">
                                <ProductSlider images={x.images}/>
                                <ProductDetailComponent product={x}/>
                            </div>
                        </Tab>)
                    }
                    )}
                        
                    </Tabs>
            }
            {/*<div className="card">
                <div className="row">
                    Detail about: {this.props.category}-{this.props.group}
                </div>
        </div>*/}
        </div>
        )
    }
}

const mapStateToProps = (state, props) => {

    let category="";
    if(state.search)
        category=state.search.category;
    
    const group=props.match.params.id;
    
    let products=[];
    let defaultTab='';
    if(state.groupOfProducts.productsgroup)
    {
        products=state.groupOfProducts.productsgroup;
        defaultTab=products[0].name;
    }
    
    return {
        category:category,
        products: products ,
        group: group,
        defaultTab: defaultTab
    }
};



export default connect(mapStateToProps, null)(ProductGroup);
