import React from 'react';

import AttributeItems from "../AttributeItems/AttributeAccordionItems";

import AttributeBool from "../AttributeBool/AttributeBool";
import AttributeRange from "../AttributeRange/AttributeRange";
import {connect} from 'react-redux';
import './AttributesFilter.scss';
import {loadProducts,invokedFilterToSearch} from "../../../actions";

const AttributesFilter = (props) => {

    const {dispatch, brandItemsCount, searchAttributes, workingMode} = props;
    
    const ISOR = workingMode === 1;//ATTRIBUTE_MODE === 'OR';

    if(props.attributeChanged)
    {
        dispatch(dispatch(invokedFilterToSearch));
        dispatch(loadProducts(props.search));
        
    }


        return (
            <>
            {searchAttributes.length>0 &&
                <div className="card mb-3 h-md-15rem h-15rem">
                    <div className="card-header">
                        <h3>Karakteristike</h3>
                    </div>
                    <ul className="list-group flex-row flex-wrap overflow-auto">
                        {searchAttributes.map((attribute, index) => ( 
                            <>
                                { attribute.type ==="string" && ((!attribute.obsolete && !ISOR) || ISOR) &&
                                <AttributeItems attributes={attribute.values} name={attribute.name} id={attribute.refId} key={attribute.refId} expandable={attribute.expandable} obsolete={attribute.obsolete} workingMode={ISOR}/>
                                }
                                { attribute.type ==="bool" && ((!attribute.obsolete && !ISOR) || ISOR) &&
                                <AttributeBool value={attribute.valueContent} name={attribute.name} id={attribute.refId} key={attribute.refId} checked={attribute.checked} obsolete={attribute.obsolete} workingMode={ISOR}/>
                                }
                                { attribute.type ==="int" && ((!attribute.obsolete && !ISOR) || ISOR)  &&
                                <AttributeRange attributes={attribute.values} name={attribute.name} id={attribute.refId} key={attribute.refId} obsolete={attribute.obsolete} workingMode={ISOR}/>
                                }
                            </>
                        ))}
                    </ul>
                </div>
            }
            </>
        );

};

const mapStateToProps = (state, props) => {

    const brandItemsCount = {};
    const searchAttributes=[];
    const minPriority=props.minPriority;
    const maxPriority=props.maxPriority;
    const workingMode = state.systemParameters.workingMode;

    let maxExpandableElements=3;
    if(minPriority>0)
        maxExpandableElements=0;

    
    const attributeChanged = state.search.filterchanged;
    const search = state.search;

    if(state.attributes.searchAttributes)
    {
        let countStringElements=0;
        

        state.attributes.searchAttributes.forEach(p => {
            if(p.name.toLowerCase()!="description")
            {
                if(parseInt(p.priority)>=minPriority && parseInt(p.priority)<=maxPriority)
                {
                    let newP=p;
                    if(newP.type==="string")
                    {
                        countStringElements++;
                        if(countStringElements<=maxExpandableElements)
                        {
                            newP.expandable=true;
                        }  
                        else
                        {
                            newP.expandable=false;
                        }
                    }
                    if(newP.type==="bool")
                    {
                        newP.checked=false;
                    }
                    newP.name=capitalizeTheFirstLetterOfEachWord(newP.name);
                    searchAttributes.push(newP);
                }
            }
        });
    }

      function capitalizeTheFirstLetterOfEachWord(words) {
        var separateWord = words.toLowerCase().split(' ');
        for (var i = 0; i < 1; i++) {
           separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
           separateWord[i].substring(1);
        }
        return separateWord.join(' ');
     }
    

    

    return {
        brandItemsCount,
        searchAttributes,
        attributeChanged,
        search,
        workingMode
    }

};

export default connect(mapStateToProps)(AttributesFilter);

const AttributesFilterRaw = (props) => {
    return(
        <></>
    )
}