import {GROUPOFPRODUCTS_RECEIVED} from "../actions";

export const  groupofproductsReducer = (state='', action) => {
    switch (action.type) {
        case GROUPOFPRODUCTS_RECEIVED:
                return { ...state, productsgroup: action.products.products, loading: false, priceRanges: action.products.priceRanges };
        default:
            return state;
    }
};

