import {combineReducers} from 'redux';
import shop from './shop.reducer';
import {brandFilterReducer} from "./brand.filter.reducer";
import {orderByPriceReducer} from "./orderByPrice.filter.reducer";
import {paginationReducer} from "./pagination.reducer";
import {productsReducer} from "./products.reducer";
import {attributeFilterReducer} from "./attributes.filter.reducer";
import {priceFilterReducer} from "./price.filter.reducer";
import {categoryReducer} from "./category.reducer";
import {searchFilterReducer} from "./search.filter.reducer";
import {groupofproductsReducer} from "./groupofproducts.reducer";
import {userLoginReducer} from "./userLogin.reducer";
import {clickRegisteredReducer} from "./clickregistered.reducer";
import {systemParametersReducer} from "./systemParameters.reducer";
import {searchModeReducer} from "./search.mode.reducer";

export default combineReducers({
    shop,
    brandFilter: brandFilterReducer,
    orderBy: orderByPriceReducer,
    pagination: paginationReducer,
    products: productsReducer,
    attributes: attributeFilterReducer,
    price:priceFilterReducer,
    categories:categoryReducer,
    search:searchFilterReducer,
    groupOfProducts: groupofproductsReducer,
    userLogin: userLoginReducer,
    click:clickRegisteredReducer,
    systemParameters:systemParametersReducer,
    searchMode:searchModeReducer,
});
