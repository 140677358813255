import {SEARCH_MODE} from "../actions";

export const  searchModeReducer = (state = '', action) => {
    switch (action.type) {
        case SEARCH_MODE:{
              return {...state, mode:action.mode}
        }
        default:
            return state;
    }
};

