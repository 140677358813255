import React from 'react';
import {connect} from 'react-redux';

import {LoginUserCookie,loadFromCookieCartCreator} from "../../actions";

const CookieLogin = (props) => {
    
    props.dispatch(LoginUserCookie());
    //props.dispatch(loadFromCookieCartCreator());

    return (
        <></>
    );
};
export default connect()(CookieLogin);