import React from 'react';
import {connect} from 'react-redux';
import "./AttributeItems.scss"
import {addFilterToSearch, removeFilterFromSearch} from "../../../actions";
import {Card} from 'react-bootstrap';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));


  function filternullvalue(value){
    if(value.content==null)
      return '';
    return value.content;
} 
 
function isnullvalue(value){
    if(value.content==null)
      return true;
    return false;
} 

function AttributeAccordionItems(props){
    let interimexpandable='panel1';
    
    if(props.expandable)
        interimexpandable='panel1';
    else
        interimexpandable='panel2';

    const [expanded, setExpanded] = React.useState(interimexpandable);
    const {dispatch} = props;
    const classes = useStyles();

    let isObsolete = props.obsolete != undefined ? props.obsolete : false;

    const colorSelected = isObsolete ? "red" : "black";

    const ISOR = props.workingMode;//ATTRIBUTE_MODE === 'OR';

    const handleSelectBox = (e) => {
        const name = e.target.name;
        const refid=props.id;
        const value = e.target.checked;

        if(e.target.checked) {
            dispatch(addFilterToSearch(name, refid));
        } else {
            dispatch(removeFilterFromSearch(name,refid));
        }
    };

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };



    return (
        
        <Accordion className="items__accordion" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading} style={{color:colorSelected}}>{props.name}</Typography>
                </AccordionSummary>
            
        {Object.values(props.attributes).map((simplevalue, index) =>
                <>
                {(((!simplevalue.obsolete && !ISOR) || ISOR) && !isnullvalue(simplevalue))  && 
                <AccordionDetails key={filternullvalue(simplevalue).replace(/\s+/g, '')}>
                <label key={filternullvalue(simplevalue).replace(/\s+/g, '')} className="custom-checkbox text-capitalize" style={{color: simplevalue.obsolete ? `red`:`black`}}> {filternullvalue(simplevalue)}
                <input key={filternullvalue(simplevalue).replace(/\s+/g, '')} type="checkbox"
                    name={filternullvalue(simplevalue)} title={props.id}
                    className="custom-checkbox__input" onInput={handleSelectBox}/> {/*onInput={handleSelectBox}*/}
                <span className="custom-checkbox__span"></span>
            </label>
            </AccordionDetails>
                }
                </>
        )
    }
</Accordion>
    )}

export default connect()(AttributeAccordionItems);