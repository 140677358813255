import {GET_PRODUCTS,GET_PRODUCTS_RECEIVED, GET_PRODUCT_LOADED, GET_PRODUCT} from "../actions";

export const  productsReducer = (state='', action) => {
    switch (action.type) {
        case GET_PRODUCTS:
            return { ...state, loading:true };
        case GET_PRODUCTS_RECEIVED:
                return { ...state, products: action.products, loading: false, priceRanges: action.priceRanges };
        case GET_PRODUCT:
            return { ...state };
        case GET_PRODUCT_LOADED:
            {
                let arrayOfProductsWithOnlyOne=[];
                arrayOfProductsWithOnlyOne.push(action.product);

                return { ...state, products: arrayOfProductsWithOnlyOne, loading: false, priceRanges: action.priceRanges};
            }
        default:
            return state;
    }
};

