import React, { Component } from "react";
import PropTypes from "prop-types";
import {uid} from "uid";
import Input from '@material-ui/core/Input';

import { Navbar,Nav,NavDropdown,Form,FormControl,Button } from 'react-bootstrap'



class ReactNestedMenuNav extends Component {
  constructor(props) {
    super(props);
    this.state = { menu: null };
    this.isTopLevelParent = true;
    this.categorynav="/?/categoryid&id=";
    
  }

  
  componentDidMount() {
    const menu = this.buildMenu(this.props.menuData);
    this.setState({ menu: menu });
  }

  componentWillReceiveProps(nextProps) {
    this.isTopLevelParent = true;
    const menu = this.buildMenu(nextProps.menuData);
    this.setState({ menu: menu });
  }


  linkTransformer = navItem => {
    if (this.props.linkTransformer) {
      return this.props.linkTransformer(navItem);
    } else {
      const navItemEl = (
        <NavDropdown.Item href={navItem[this.props.navUrlProperty]} onSelect={this.props.trigger}>
          {" "}
          {navItem[this.props.navTitleProperty]}{" "}
        </NavDropdown.Item>
      );

      return navItemEl;
    }
  };

  linkTransformerChilds = navItem => {
      const navItemEl = navItem.children.map(x=>
            /*<NavDropdown.Item eventKey={x.id} onSelect={this.props.trigger} key={uid(15)}>{x.title} */
            <NavDropdown.Item href={this.categorynav+x.referenceid}>
            {x.title}
        </NavDropdown.Item>
      );
      return navItemEl;
  };

  endElement= name =>{
    return(
      <NavDropdown.Item eventKey={uid(15)} onSelect={this.props.trigger} key={uid(15)}>
              Sve kategorije
        </NavDropdown.Item>
    )
  }

  buildMenu = menuData => {
    let parentClassname = null;
    if (this.isTopLevelParent) {
      parentClassname = `${this.props.navTopLevelParentClassname}`;
      this.isTopLevelParent = false;
    } else {
      parentClassname = `${this.props.navParentClassname}`;
    }

    const childMenuItems = menuData.map(el => {
      const ChildTag = `${this.props.navChildElement}`;
      const childChildren = (
        <div>
          
          {el[this.props.childMenuProperty] 
            ? <NavDropdown
            className={this.props.navChildClassname} title={el.title} name={el.referenceid} 
            key={el[this.props.arrayKey] ? el[this.props.arrayKey] : uid(15)}
            >{this.linkTransformerChilds(el)}
            {/*this.buildMenu(el[this.props.childMenuProperty])*/}
            </NavDropdown>
            : <Nav.Link href="#home">{el.title}</Nav.Link>}
            </div>
      );

      return childChildren;
    });

    const ParentTag = `${this.props.navParentElement}`;

    return <Navbar.Collapse><Nav className="mr-auto">{childMenuItems}</Nav><form  inline>
    {/*<FormControl type="text" placeholder="Traži" className="mr-sm-2" 
        onKeyPress={handleKeypress}
        onChange = {(event) => this.props.searchClick(event.target.value , event)}
  />*/}
        <Input placeholder="Trazim..." inputProps={{ 'aria-label': 'description' }}
        onKeyPress={(e)=> {this.props.searchOnEnterClick(e.target.value,e);}}
        onChange = {(event) => this.props.searchClick(event.target.value , event)}>
      </Input>
        {/*
    <Button variant="outline-success">Traži</Button>*/}
    </form></Navbar.Collapse>;
  };

  render() {

    return this.state.menu;
  }
}

ReactNestedMenuNav.propTypes = {
  navParentElement: PropTypes.string,
  navChildElement: PropTypes.string,
  navParentClassname: PropTypes.string,
  navTopLevelParentClassname: PropTypes.string,
  navChildClassname: PropTypes.string,
  childMenuProperty: PropTypes.string,
  navUrlProperty: PropTypes.string,
  navTitleProperty: PropTypes.string,
  arrayKey: PropTypes.string,
  linkTransformer: PropTypes.func,
  menuData: PropTypes.array.isRequired
};

ReactNestedMenuNav.defaultProps = {
  navParentElement: "ul",
  navChildElement: "li",
  navChildElementClassname: "",
  navParentClassname: "",
  navTopLevelParentClassname: "",
  navChildClassname: "",
  navUrlProperty: "url",
  childMenuProperty: "children",
  navTitleProperty: "title",
  arrayKey: "id"
};

export default ReactNestedMenuNav;
