import {GET_PRODUCTS_RECEIVED} from "../actions";

export const  attributeFilterReducer = (state = '', action) => {

    switch (action.type) {
        case GET_PRODUCTS_RECEIVED:
            {
                if(!action.attributes)
                {
                    return state;
                } 
                
                let inputAttributes=action.attributes;

                const availableAttributes = markFilterItemAsObsolete(state.searchAttributes, inputAttributes);
                
                const brandAttribute=availableAttributes.findIndex(item => item.type === "brand");
                return { ...state, searchAttributes:availableAttributes, loading: false, brandAttributeId: availableAttributes[brandAttribute] };
            }
        
        default:
            return state;
    }
};

function markFilterItemAsObsolete(originalAttributes, newAttributes)
{
    const ATTRIBUTE_MODE = process.env.NODE_ENV === 'production' ? window.env.REACT_ATTRIBUTE_MODE : window.env.ATTRIBUTE_MODE;
    if(originalAttributes)
    {
        const instersectAttributes=intersectPropertyFunction(originalAttributes,newAttributes);
        return instersectAttributes;
    }
    else
    {
        const copyOfAttributes = newAttributes.map(x => {
            return {...x, obsolete: false}
        });
        const modifiedCopyOfAttributes=copyOfAttributes.map(x => {
            return {...x, values: x.values.map(y => {
                return {content:y, obsolete:false}
            })
        }
    });
        return modifiedCopyOfAttributes;
    }

    return newAttributes;
}

function intersectPropertyFunction(arr1, arr2)
{
    
    let array2 = arr1.map(a => {
        var returnValue = {...a};
        const found=arr2.findIndex(x=> x.refId === a.refId); 
        if(found != -1)
        {
            const returnValues=a.values.map(processValues(arr2[found]));
            returnValue = {...a, obsolete:false, values: returnValues};
        }
        else
        {
            returnValue = {...a, obsolete:true};
        }
      
        return returnValue
      })

    return array2;
}

function processValues(positionValue)
{
    return function handleResponseValue(y)
    {
        var returnValue = y;
        const found2=positionValue.values.findIndex(z=> z === y.content);
        if( found2 != -1)
        {
            returnValue = {...y, obsolete: false};
        }
        else
        {
            returnValue = {...y, obsolete: true}; 
        }

        return returnValue;

    }
}

