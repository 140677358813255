import React from 'react';
import {connect} from 'react-redux';
 import { useFormik } from 'formik';
 import { submitForm } from "../../actions";
 
 const validate = values => {
    const errors = {};
    if (!values.company) {
      errors.company = 'Obavezno';
    } else if (values.company.length < 4) {
      errors.company = 'Mora biti barem 4 karaktera';
    }
  
    if (!values.weburl) {
      errors.weburl = 'Obavezno';
    } else if (values.weburl.length < 20) {
      errors.weburl = 'Mora biti barem 20 karaktera duzine';
    }
  
    if (!values.email) {
      errors.email = 'Obavezno';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Nevalidna email adresa';
    }
  
    return errors;
  };

 const ContactForm = (props) => {

        const {dispatch} = props;
   // Pass the useFormik() hook initial form values and a submit function that will
   // be called when the form is submitted
   const formik = useFormik({
     initialValues: {
       company:'',
       email: '',
       weburl: '',
     },
     validate,
     onSubmit: values => {
       //alert(JSON.stringify(values, null, 2));
       dispatch(submitForm(JSON.stringify(values, null, 2)));
     },
   });
   return (
    <div className="formWrapper">
     <form className="baseForm" onSubmit={formik.handleSubmit}>
        <header className="baseFormHeader">
            <h1 className="baseFormHeading">Registracija</h1>
          </header>
          <div className="formRow col-1">
            <div className="formFieldWrap">
                <label className="formFieldLabel" htmlFor="company">Naziv</label>
            
         <div className="formFieldWrapInner">
       <input
         id="company"
         name="company"
         type="text"
         className="email formField"
         onChange={formik.handleChange}
         onBlur={formik.handleBlur}
         value={formik.values.company}
       /></div></div>
       </div>
       {formik.touched.company && formik.errors.company ? <div>{formik.errors.company}</div> : null}

       <div className="formRow col-1">
            <div className="formFieldWrap">
       <label className="formFieldLabel"  htmlFor="weburl">Web sajt</label>
       <div className="formFieldWrapInner"><input
         id="weburl"
         name="weburl"
         type="text"
         className="email formField"
         onChange={formik.handleChange}
         onBlur={formik.handleBlur}
         value={formik.values.weburl}
       /></div></div></div>
       {formik.touched.weburl &&  formik.errors.weburl ? <div>{formik.errors.weburl}</div> : null}

       <div className="formRow col-1">
            <div className="formFieldWrap">
       <label className="formFieldLabel"  htmlFor="email">Email</label>
       <input
         id="email"
         name="email"
         type="email"
         className="email formField"
         onChange={formik.handleChange}
         value={formik.values.email}
       /></div></div>
 <div className="formRow col-1">
       <button type="submit">Submit</button>
       </div>
     </form>
     </div>
   );
 };

 export default connect()(ContactForm);