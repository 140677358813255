import { List } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux'
import { loadProducts } from '../../actions'
import ModeSelect from './ModeSelect';

let SearchMode = ({ searchMode }) => (
  <ModeSelect mode={searchMode}></ModeSelect>
);

const mapStateToProps = (state) => {
  
  return {
  searchMode: state.searchMode.mode
}
}

const mapDispatchToProps = {
  getPosts: loadProducts
}

SearchMode = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchMode)

export default SearchMode;
